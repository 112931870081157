import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import TgcLink from '../../../common/TgcLink/TgcLink'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation'
import Parallax from '../../../common/ParallaxAnimation/Parallax.jsx'
import PropTypes from 'prop-types'

const TechUsed = ({ work }) => (
  <section className='lg:grid grid-cols-3 md:mt-40 mt-20'>
    <div className='lg:flex flex-col h-full'>
      <FadeInUpAnimation>
        <div className='pb-8 lg:text-5xl text-4xl'>Verwendete Tech</div>
      </FadeInUpAnimation>
      <ul className='grid lg:grid-cols-1 sm:grid-cols-2 grid-cols-1 mb-6 lg:w-10/12'>
        {work.techsUsed.map((tech, index) => (
          <FadeInUpAnimation
            key={index}
            as='li'
            className={`mr-4 first:border-t text-black py-3 border-b border-primary ${
              index === 1 ? 'sm:border-t lg:border-t-0 border-t-0' : ''
            }`}
          >
            <div>
              <p className='py-2'>
                <span className='text-brand-primary'>→ </span>
                {tech}
              </p>
            </div>
          </FadeInUpAnimation>
        ))}
      </ul>

      <FadeInUpAnimation delay={0.3}>
        <div className='my-10 xl:my-0'>
          <TgcLink
            className='openBtn'
            href={work.webSite}
          >
            Zur Webseite
          </TgcLink>
        </div>
      </FadeInUpAnimation>
    </div>
    <Parallax className='col-span-2 flex'>
      <FadeInUpAnimation>
        <GatsbyImage
          className='aspect-video rounded-2xl'
          image={work.imgs[2]?.childImageSharp?.gatsbyImageData}
          alt={work.title}
          loading='lazy'
          layout='rounded-2xl'
        />
      </FadeInUpAnimation>
    </Parallax>
  </section>
)

export default TechUsed

TechUsed.propTypes = {
  work: PropTypes.shape({
    techsUsed: PropTypes.array.isRequired,
    imgs: PropTypes.object,
    title: PropTypes.string.isRequired,
    webSite: PropTypes.string.isRequired
  }).isRequired
}
