import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../../tailwind.config'
import { useEffect, useRef } from 'react'
import { gsap } from '../../gsap'

const fullConfigTailwind = resolveConfig(tailwindConfig)
const breakpoint = parseInt(fullConfigTailwind.theme.screens.lg.slice(0, -2))

const useProjectsAnimation = (withFooter = false) => {
  const animationContainerRef = useRef(null)
  const triggerRef = useRef(null)

  useEffect(() => {
    const animationContainer = animationContainerRef.current
    const trigger = triggerRef.current
    const footer = withFooter
      ? document.body.querySelector('#main-footer')
      : null

    const mm = gsap.matchMedia()

    mm.add(
      {
        isMobile: `(max-width: ${breakpoint - 1}px)`,
        isTablet: `(min-width: ${breakpoint}px)`
      },
      (context) => {
        const projectsArr = gsap.utils.toArray('.project')
        const { isTablet } = context.conditions
        const projectsNumberShown = isTablet ? 2 : 1

        withFooter &&
          gsap.set(footer, {
            maxHeight: 0,
            padding: 0,
            overflow: 'hidden'
          })

        let isAnimationEnabled = true

        if (window.innerWidth <= breakpoint - 1) {
          isAnimationEnabled = false
        }

        if (isAnimationEnabled) {
          gsap.to(projectsArr, {
            xPercent: -110 * (projectsArr.length - projectsNumberShown),
            ease: 'none',
            scrollTrigger: {
              pin: animationContainer,
              ease: 'none',
              trigger,
              scrub: 0.5,
              start: 'center center',
              end: '+=' + 4000,
              onEnter: (self) => self.refresh()
            }
          })
        }
      },
      animationContainerRef
    )

    return () => {
      mm.revert()
    }
  }, [])

  return {
    animationContainerRef,
    triggerRef
  }
}

export default useProjectsAnimation
