import React from 'react'
import FadeInUpAnimation from '../FadeInUpAnimation/FadeInUpAnimation.jsx'
import Parallax from '../ParallaxAnimation/Parallax.jsx'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import Tags from './Tags'

const ProjectInfo = ({ work }) => (
  <section>
    <FadeInUpAnimation>
      <p className='mb-24 text-4xl leading-snug font-light lg:text-6xl'>
        {work.subTitle}
      </p>
    </FadeInUpAnimation>
    <FadeInUpAnimation delay={0.3}>
      <Parallax>
        <GatsbyImage
          className='aspect-video rounded-2xl md:h-full h-96'
          image={work.imgs[0]?.childImageSharp?.gatsbyImageData}
          alt={work.title}
          loading='lazy'
          layout='fullWidth'
        />
      </Parallax>
    </FadeInUpAnimation>
    <div className='flex flex-wrap gap-3 mt-3'>
      {work.tags.map((tag, index) => (
        <FadeInUpAnimation key={index}>
          <div className='mt-4'>
            <Tags>{tag}</Tags>
          </div>
        </FadeInUpAnimation>
      ))}
    </div>
  </section>
)

export default ProjectInfo

ProjectInfo.propTypes = {
  work: PropTypes.object.isRequired
}
