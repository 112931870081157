import React from 'react'
import FadeInUpAnimation from '../FadeInUpAnimation/FadeInUpAnimation'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import useLineAnimation from '../hooks/useLineAnimation'
import ColorsFilter from '../ColorsFilter'

const ClientInfo = ({ work }) => {
  const lineRef = useLineAnimation()
  return (
    <div
      className='overflow-hidden'
      ref={lineRef}
    >
      <article className='flex justify-end'>
        <FadeInUpAnimation className='lg:w-2/3 w-full  lg:my-56 my-20'>
          <div className='lg:text-5xl md:text-4xl text-2xl leading-normal'>
            "{work.clientReview}"
          </div>
          <div className='flex items-center md:gap-10 gap-5 my-10'>
            <ColorsFilter className='inline-block md:mr-5 rounded-full z-0'>
              <GatsbyImage
                className='rounded-full md:w-32 md:h-32 sm:w-24 sm:h-24 w-24 h-20'
                image={work.clientAvatar?.childImageSharp?.gatsbyImageData}
                alt={`${work.clientName} avatar`}
                loading='lazy'
                imgClassName='rounded-full md:w-32 md:h-32 sm:w-24 sm:h-24 w-24 h-20'
              />
            </ColorsFilter>
            <div className='my-10 md:font-medium md:text-2xl xs:text-base text-sm font-medium'>
              <p>{work.clientName}</p>
              <p className='text-brand-primary'>{work.clientPosition}</p>
            </div>
          </div>
        </FadeInUpAnimation>
      </article>
      <div className='line h-[1px] w-full bg-line-grey' />
    </div>
  )
}

export default ClientInfo

ClientInfo.propTypes = {
  work: PropTypes.object.isRequired
}
