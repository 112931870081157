import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const containerClassNames = (className) =>
  clsx('relative overflow-hidden rounded-2xl z-0', className)

const Parallax = ({ children, className }) => {
  const containerRef = useRef(null)
  const animatedElementRef = useRef(null)

  useEffect(() => {
    const container = containerRef.current
    const animatedElement = animatedElementRef.current

    const ctx = gsap.context(() => {
      gsap.fromTo(
        animatedElement,
        {
          translateY: '-5%'
        },
        {
          translateY: '5%',
          scrollTrigger: {
            trigger: container,
            start: 'top 80%',
            end: 'bottom 20%',
            scrub: true
          }
        }
      )
    }, containerRef)
    return () => {
      ctx.revert()
    }
  }, [])

  return (
    <div
      ref={containerRef}
      className={containerClassNames(className)}
    >
      <div
        ref={animatedElementRef}
        className='relative w-full h-full rounded-2xl'
      >
        {children}
      </div>
    </div>
  )
}

export default Parallax

Parallax.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Parallax.defaultTypes = {
  className: ''
}
