import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import FadeInUpAnimation from '../../../common/FadeInUpAnimation/FadeInUpAnimation.jsx'
import Parallax from '../../../common/ParallaxAnimation/Parallax.jsx'
import PropTypes from 'prop-types'
import useLineAnimation from '../../../common/hooks/useLineAnimation'

const ProjectDesc = ({ work }) => {
  const lineRef = useLineAnimation()

  return (
    <section className='lg:my-32 my-20'>
      <div
        className='overflow-hidden'
        ref={lineRef}
      >
        <div className='line h-[1px] w-full bg-line-grey' />
        <FadeInUpAnimation className='grid md:grid-cols-3 md:my-10 my-5 '>
          <div className='lg:text-5xl text-4xl mb-10'>Das Projekt</div>
          <p className='col-span-2'>{work.clientIdeaDesc}</p>
        </FadeInUpAnimation>
      </div>
      <FadeInUpAnimation delay={0.3}>
        <Parallax className='ml-auto w-full my-10 lg:w-2/3 lg:my-20'>
          <GatsbyImage
            className='aspect-video rounded-2xl'
            image={work.imgs[1]?.childImageSharp?.gatsbyImageData}
            alt={work.title}
            loading='lazy'
            layout='fullWidth'
          />
        </Parallax>
      </FadeInUpAnimation>
    </section>
  )
}

export default ProjectDesc

ProjectDesc.propTypes = {
  work: PropTypes.object.isRequired
}
