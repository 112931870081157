import React from 'react'
import SectionHeader from '../../../common/Header/SectionHeader'
import ContentContainer from '../../../common/Layout/ContentContainer'
import ProjectInfo from '../../../common/SingleWork/ProjectInfo'
import ProjectDesc from './ProjectDesc'
import TechUsed from './TechUsed'
import ClientInfo from '../../../common/SingleWork/ClientInfo'
import MoreWork from './MoreWork'
import WhatWeDid from './WhatWeDid'
import useProjectsAnimation from '../../../common/hooks/useProjectsAnimation'
import PropTypes from 'prop-types'

const SingleWorkPage = ({ project, footer }) => {
  const { animationContainerRef, triggerRef } = useProjectsAnimation(true)

  return (
    <section>
      <SectionHeader wordsToType={[project.title]} />
      <ContentContainer id='wrapper'>
        <ProjectInfo work={project} />
        <ProjectDesc work={project} />
        <WhatWeDid work={project} />
        <TechUsed work={project} />
      </ContentContainer>
      <div ref={animationContainerRef}>
        <ContentContainer>
          <ClientInfo work={project} />
          <MoreWork
            relatedWorkIds={project.relatedWorkIds}
            ref={triggerRef}
          />
        </ContentContainer>
        {footer}
      </div>
    </section>
  )
}

export default SingleWorkPage

SingleWorkPage.propTypes = {
  project: PropTypes.object.isRequired,
  footer: PropTypes.node
}
