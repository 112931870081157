import React from 'react'
import { LocalizedLink } from 'gatsby-theme-i18n'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const tagsClassNames = (className) =>
  clsx(
    'button md:font-medium font-light sm:text-xl text-lg px-5 py-2 text-brand-primary border border-brand-primary rounded-full cursor-auto',
    className
  )

const Tags = ({ children, href, className }) => (
  <LocalizedLink
    className={tagsClassNames(className)}
    to={href}
  >
    {children}
  </LocalizedLink>
)

export default Tags

Tags.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  className: PropTypes.string
}

Tags.defaultProps = {
  children: '',
  href: '/',
  className: ''
}
