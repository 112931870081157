import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Project from './Project'

const allProjectsWrapper = (className) =>
  clsx(
    'w-[calc(100vw-1.5rem)] lg:w-[calc(100vw-3rem)] xl:w-full',
    'mb-8 lg:mb-0 overflow-hidden overflow-y-hidden lg:overflow-x-hidden overflow-x-auto  whitespace-nowrap',
    className
  )

const Projects = ({ className, sortedProjects }) => (
  <div className={allProjectsWrapper(className)}>
    {sortedProjects.map((project) => (
      <Project
        key={project.title}
        {...project}
      />
    ))}
  </div>
)
export default Projects

Projects.propTypes = {
  className: PropTypes.string,
  sortedProjects: PropTypes.array
}

Projects.defaultProps = {
  className: ''
}
