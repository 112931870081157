import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import LayoutDE from '../Layout/Layout'
import SingleWorkPage from './SingleWork/SingleWorkPage'
import Footer from '../Footer/Footer'

const SingleWorkPageTemplate = ({ data }) => {
  const {
    allFile: { edges }
  } = data
  const { frontmatter } = edges[0].node.childMarkdownRemark

  return (
    <LayoutDE
      title={`${frontmatter.title} | the.good.code;`}
      description={frontmatter.whatWeDidDesc}
    >
      <SingleWorkPage
        project={frontmatter}
        footer={
          <Footer
            content={frontmatter.slogan}
            aria-hidden
          />
        }
      />
    </LayoutDE>
  )
}

export const query = graphql`
  query ($id: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        relativeDirectory: { eq: "works" }
        childMarkdownRemark: { id: { eq: $id } }
      }
    ) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              id
              title
              slogan
              description
              techsUsed
              webSite
              tags
              subTitle
              clientIdeaDesc
              whatWeDidDesc
              clientReview
              clientAvatar {
                childImageSharp {
                  gatsbyImageData
                }
              }
              clientName
              clientPosition
              imgs {
                childImageSharp {
                  gatsbyImageData
                }
              }
              relatedWorkIds
              url
            }
          }
        }
      }
    }
  }
`

export default SingleWorkPageTemplate

SingleWorkPageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}
