import React from 'react'
import ContentContainer from '../../../common/Layout/ContentContainer'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'

const PrivacyPolicyModalContent = () => (
  <ContentContainer>
    <div className='mb-30 space-y-10 text-sm'>
      <TextWithDots>Datenschutzrichtlinie</TextWithDots>
      <p>
        Diese Datenschutzrichtlinie beschreibt, wie wir Informationen von
        Besuchern unserer Website sammeln, verwenden und offenlegen. Durch die
        Nutzung unserer Website stimmen Sie der Sammlung und Verwendung von
        Informationen gemäß dieser Datenschutzrichtlinie zu.
      </p>
      <h4>Informationssammlung und -verwendung</h4>
      <p>
        Wir verwenden Google Analytics, um Informationen darüber zu sammeln, wie
        Besucher unsere Website nutzen. Dazu gehören Informationen wie die
        IP-Adresse Ihres Geräts, der Browsertyp und die besuchten Seiten. Wir
        verwenden diese Informationen zur Analyse des Website-Verkehrs und zur
        Verbesserung der Benutzererfahrung. Google Analytics sammelt diese
        Informationen mithilfe von Cookies, kleinen Dateien, die auf Ihrem Gerät
        gespeichert werden.
      </p>
      <p className='mt-5'>
        Wir können auch personenbezogene Informationen sammeln, die Sie uns zur
        Verfügung stellen, wie Ihren Namen und Ihre E-Mail-Adresse, wenn Sie
        sich für unseren Newsletter anmelden oder uns über unsere Website
        kontaktieren.
      </p>
      <h4>Informationen teilen</h4>
      <p>
        Wir können Ihre persönlichen Informationen mit Drittanbietern teilen,
        die Dienstleistungen in unserem Auftrag erbringen, wie zum Beispiel
        E-Mail-Marketing oder Website-Hosting. Diese Dienstleister sind nicht
        berechtigt, Ihre persönlichen Informationen für einen anderen Zweck als
        die Erbringung von Dienstleistungen für uns zu verwenden oder
        offenzulegen.
      </p>
      <p>
        Wir können Ihre persönlichen Informationen auch offenlegen, wenn dies
        gesetzlich vorgeschrieben ist oder wenn wir glauben, dass eine solche
        Maßnahme notwendig ist, um gesetzlichen Anforderungen zu entsprechen,
        unsere Rechte oder die Rechte anderer zu schützen oder Betrug oder
        andere illegale Aktivitäten zu verhindern.
      </p>
      <h4>Informationssicherheit</h4>
      <p>
        Wir treffen angemessene Maßnahmen, um Ihre persönlichen Informationen
        vor unbefugtem Zugriff, Offenlegung oder Nutzung zu schützen. Keine
        Übertragung über das Internet oder elektronische Speicherung ist jedoch
        zu 100 % sicher, daher können wir keine absolute Sicherheit garantieren.
      </p>
      <h4>Links zu anderen Websites</h4>
      <p>
        Unsere Website kann Links zu anderen Websites enthalten, die nicht im
        Besitz oder unter der Kontrolle von uns stehen. Wir sind nicht für die
        Datenschutzpraktiken oder den Inhalt dieser Websites verantwortlich.
      </p>
      <h4>Datenschutz für Kinder</h4>
      <p>
        Unsere Website ist nicht für die Nutzung durch Kinder unter 13 Jahren
        vorgesehen. Wir sammeln nicht wissentlich personenbezogene Informationen
        von Kindern unter 13 Jahren. Wenn uns bekannt wird, dass wir
        personenbezogene Informationen von einem Kind unter 13 Jahren gesammelt
        haben, werden wir Schritte unternehmen, um diese Informationen zu
        löschen.
      </p>
      <h4>Änderungen dieser Datenschutzrichtlinie</h4>
      <p>
        Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren.
        Die aktualisierte Datenschutzrichtlinie wird auf unserer Website
        veröffentlicht, und das Datum der neuesten Überarbeitung wird oben auf
        der Datenschutzrichtlinie angezeigt.
      </p>
      <h4>Kontakt</h4>
      <p className='group  transition-all duration-300 ease-in-out'>
        Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, kontaktieren Sie
        uns bitte unter{' '}
        <a
          href='mailto:contacts@thegoodcode.io'
          className='text-brand-primary bg-left-bottom bg-gradient-to-r from-brand-primary to-brand-primary bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'
        >
          contact@thegoodcode.io.
        </a>
      </p>
    </div>
  </ContentContainer>
)

export default PrivacyPolicyModalContent
