import React, { useState } from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from '@reach/router'
import TextWithDots from '../../../common/TextWithDots/TextWithDots'
import ArrowRightIcon from '../../../common/Icons/ArrowRightIcon'
import Modal from '../../../common/Cookies/Modal'
import TgcLink from '../../../common/TgcLink/TgcLink'
import UnderlineEffect from '../../../common/UnderlineEffect'
import PrivacyPolicyModalContent from './PrivacyPolicyModalContent'

const CookieConsentBanner = () => {
  const location = useLocation()
  const [modalOpened, setModalOpened] = useState(false)

  return (
    <CookieConsent
      location='top'
      buttonText={<TgcLink className='text-sm md:text-2xl'>Zustimmen</TgcLink>}
      enableDeclineButton
      declineButtonText={
        <TgcLink className='text-sm md:text-2xl'>Ablehnen</TgcLink>
      }
      cookieName='tgc-CookieConsent'
      disableButtonStyles
      disableStyles
      overlay
      overlayClasses='fixed left-0 top-0 w-full h-full bg-black/30 z-20'
      containerClasses='fixed left-0  top-0 w-full flex flex-col flex-wrap bg-white text-black shadow-gray-500 shadow-md z-20'
      contentClasses='max-w-[1440px] mx-auto px-6 lg:px-12 mt-10 text-sm lg:text-lg lg:mx-auto'
      buttonWrapperClasses='w-full max-w-[1440px] px-6 lg:px-12 gap-5 mb-10 flex justify-end mt-4 mx-auto'
      onAccept={() => {
        Cookies.set('tgc-tagmanager', true, { expires: 365 })
        initializeAndTrack(location)
      }}
      onDecline={() => {
        Cookies.set('tgc-CookieConsent', false)
        Cookies.set('tgc-tagmanager', false)
      }}
    >
      <TextWithDots className='mb-12'>Datenschutzhinweis</TextWithDots>
      <p>
        Diese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden
        verwendet, um Informationen über Ihre Interaktion mit unserer Website zu
        sammeln und es uns zu ermöglichen, uns an Sie zu erinnern. Wir nutzen
        diese Informationen, um Ihre Website-Erfahrung zu verbessern und
        Analysen und Kennzahlen über unsere Besucher auf dieser Website und
        anderen Medienplattformen zu erstellen. Weitere Informationen zu den von
        uns verwendeten Cookies finden Sie in unserer Datenschutzerklärung.
      </p>
      <p className='my-10'>
        Wenn Sie ablehnen, werden Ihre Informationen nicht gesammelt, wenn Sie
        diese Website besuchen. Es wird ein einzelnes Cookie in Ihrem Browser
        gesetzt, um sich daran zu erinnern, dass Sie nicht verfolgt werden
        möchten.
      </p>
      <p className='w-fit cursor-pointer text-brand-primary'>
        <UnderlineEffect>
          <span onClick={() => setModalOpened(true)}>
            Mehr Informationen hier
          </span>
          <ArrowRightIcon className='inline ml-2 text-brand-primary w-4' />
        </UnderlineEffect>
      </p>
      {modalOpened && (
        <Modal hideModal={() => setModalOpened(false)}>
          <PrivacyPolicyModalContent />
        </Modal>
      )}
    </CookieConsent>
  )
}

export default CookieConsentBanner
