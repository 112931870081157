import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { graphql, Link, useStaticQuery } from 'gatsby'

const projectWrapperClassNames = (className) =>
  clsx(
    'project inline-block box-content',
    'w-[calc(100%-1.5rem)] lg:w-[calc(50%-1.5rem)] xl:w-1/2',
    className
  )

const query = graphql`
  query {
    file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "ourProjects/projectImg.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
const Project = ({ title, subTitle, imgs, url, className }) => {
  const {
    file: {
      childImageSharp: { gatsbyImageData: defaultImage }
    }
  } = useStaticQuery(query)
  return (
    <div className={projectWrapperClassNames(className)}>
      <div className='flex flex-col items-start px-1 lg:px-2 my-10 lg:my-0'>
        <Link to={`/${url}`}>
          <GatsbyImage
            className='aspect-video rounded-2xl'
            image={imgs?.[0]?.childImageSharp?.gatsbyImageData || defaultImage}
            alt={title}
            loading='lazy'
            layout='fullWidth'
            imgClassName='rounded-2xl openBtn'
          />
        </Link>
        <div className='mt-4 lg:mt-4 xl:mt-6 whitespace-pre-wrap'>
          <h3 className='mb-2 text-2xl leading-7 lg:text-3xl lg:leading-snug'>
            {title}
          </h3>
          <p className='text-lg leading-6 font-normal lg:text-xl lg:leading-7'>
            {subTitle}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Project

Project.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  imgs: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.string,
  className: PropTypes.string
}

Project.defaultProps = {
  title: '',
  subTitle: '',
  imgs: [{}],
  url: '/',
  className: ''
}
